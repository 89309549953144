import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styles from "./AnswerScale.module.scss";

const AnswerScale: React.FC<AnswerScaleProps> = ({
  scaleAnswer,
  type,
  setScaleAnswer,
}) => {
  return (
    <>
      <Row className={styles.answerRow}>
        <Col
          className={scaleAnswer === 1 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(1)}
        >
          <span>1</span>
        </Col>
        <Col
          className={scaleAnswer === 2 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(2)}
        >
          <span>2</span>
        </Col>
        <Col
          className={scaleAnswer === 3 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(3)}
        >
          <span>3</span>
        </Col>
        <Col
          className={scaleAnswer === 4 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(4)}
        >
          <span>4</span>
        </Col>
        <Col
          className={scaleAnswer === 5 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(5)}
        >
          <span>5</span>
        </Col>
        <Col
          className={scaleAnswer === 6 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(6)}
        >
          <span>6</span>
        </Col>
        <Col
          className={scaleAnswer === 7 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(7)}
        >
          <span>7</span>
        </Col>
        <Col
          className={scaleAnswer === 8 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(8)}
        >
          <span>8</span>
        </Col>
        <Col
          className={scaleAnswer === 9 ? styles.answerActive : styles.answerCol}
          onClick={() => setScaleAnswer(9)}
        >
          <span>9</span>
        </Col>
        <Col
          className={
            scaleAnswer === 10 ? styles.answerActive : styles.answerCol
          }
          onClick={() => setScaleAnswer(10)}
        >
          <span>10</span>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 5 }}>
          <span className={styles.textLeft}>
            {type === "scale1" ? "Mało znaczący" : "Mało ważne"}
          </span>
        </Col>
        <Col xs={{ span: 5, offset: 2 }}>
          <span className={styles.textRight}>
            {type === "scale1" ? "Bardzo znaczący" : "Bardzo ważne"}
          </span>
        </Col>
      </Row>
    </>
  );
};

export interface AnswerScaleProps {
  scaleAnswer: number | undefined;
  type: string;
  setScaleAnswer: (value: number) => void;
}

export default AnswerScale;
