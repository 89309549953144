import useCalculator from "hooks/useCalculator";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";
import styles from "./SummaryPage.module.scss";

const SummaryPage: React.FC<SummaryPageProps> = () => {
  const { calculator } = useCalculator();
  // console.log(calculator);
  

  const getPosition = (
    baseString: string,
    target: string,
    whichTarget: number
  ) => {
    return baseString.split(target, whichTarget).join(target).length;
  };
  const mainTechs = calculator?.technologies?.main;
  const altTechs = calculator?.technologies?.alt;

  const techDescriptions = [];
  const altTechDescriptions = [];
  if (mainTechs !== undefined) {
    for (const tech of mainTechs) {
      let branches = [tech.branch1, tech.branch2, tech.branch3];
      const unique = branches.filter(value => calculator.branches.includes(value.id)).filter((value, index, self) =>
      index === self.findIndex((t) => (t.id === value.id)))
      techDescriptions.push(
        <div key={tech.id}>
          {mainTechs.length > 1 && tech === mainTechs[0] ? (
            <Row>
              <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                <div className={styles.fontBig}>
                  <strong>Technologie główne</strong>
                </div>
              </Col>
            </Row>
          ) : tech === mainTechs[0] ? (
            <Row>
              <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                <div className={styles.fontBig}>
                  <strong>Technologia główna</strong>
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className={"my-3"}>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div className={styles.name}>{tech.tech_name}</div> {/* nazwa technologii */}
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col xs={12} sm={2} className={`${styles.justifier} pb-3`}>
              <div className={styles.square}>
                <img className={styles.square} src={tech.icon} alt={""}></img>
              </div>
            </Col>
            <Col xs={12} sm={10} className={`pb-3`}>
              {tech.tech_descr}
            </Col>
          </Row>

          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>PROBLEM</strong>
              </div>
              <div>{calculator.problems[0].problem_name}</div> {/* nazwa problemu */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div>{calculator.problems[0].problem_descr}</div> {/* opis problemu */}
              <hr />
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>DZIAŁ</strong>
              </div>
              <div>{unique.map((val, i) => i === unique.length - 1 ? val.branch : val.branch + ', ')}</div> {/* nazwa działu */}
            </Col>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <hr />
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>KOSZT</strong>
              </div>
              <div>{tech.tech_cost} tys. zł</div> {/* nazwa kosztu */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div>{tech.cost_descr}</div> {/* opis kosztu */}
              <hr />
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>KORZYŚCI</strong>
              </div>
              <div>{tech.tech_profit}%</div> {/* nazwa korzyści */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div>{tech.profit_descr}</div> {/* opis korzyści */}
              <hr />
            </Col>
          </Row>
        </div>
      );
    }
  }

  if (altTechs !== undefined && calculator.problems.length > 1) {
    for (const tech of altTechs) {
      let branches = [tech.branch1, tech.branch2, tech.branch3];
      const unique = branches.filter(value => calculator.branches.includes(value.id)).filter((value, index, self) =>
      index === self.findIndex((t) => (t.id === value.id)))

      altTechDescriptions.push(
        <div>
          {altTechs.length > 1 && tech === altTechs[0] ? (
            <Row className={"my-3"}>
              <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                <div className={styles.fontBig}>
                  <strong>Technologie alternatywne</strong>
                </div>
              </Col>
            </Row>
          ) : tech === altTechs[0] ? (
            <Row className={"my-3"}>
              <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                <div className={styles.fontBig}>
                  <strong>Technologia alternatywna</strong>
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className={"my-3"}>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div className={styles.name}>{tech.tech_name}</div> {/* nazwa technologii */}
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col xs={12} sm={2} className={`${styles.justifier} pb-3`}>
              <div className={styles.square}>
                <img className={styles.square} src={tech.icon} alt={""}></img>
              </div>
            </Col>
            <Col xs={12} sm={10} className={`pb-3`}>
              {tech.tech_descr}
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>PROBLEM</strong>
              </div>
              <div>{calculator.problems[1].problem_name}</div> {/* nazwa problemu */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div>{calculator.problems[1].problem_descr}</div> {/* opis problemu */}
              <hr />
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>DZIAŁ</strong>
              </div>
              <div>{unique.map((val, i) => i === unique.length - 1 ? val.branch : val.branch + ', ')}</div> {/* nazwa działu */}
            </Col>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <hr />
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>KOSZT</strong>
              </div>
              <div>{tech.tech_cost} tys. zł</div> {/* nazwa kosztu */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div>{tech.cost_descr}</div> {/* opis kosztu */}
              <hr />
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>KORZYŚCI</strong>
              </div>
              <div>{tech.tech_profit}%</div> {/* nazwa korzyści */}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div>{tech.profit_descr}</div> {/* opis korzyści */}
              <hr />
            </Col>
          </Row>
        </div>
      );
    }
  }

  const instruments = [];
  if (calculator.instruments !== undefined) {
    for (const inst of calculator.instruments) {
      instruments.push(
        <div key={inst.id}>
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <div className={styles.name}>{inst.instrument_name}</div> {/* nazwa instrumentu */}
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col xs={12} sm={2} className={`${styles.justifier} pb-3`}>
              <div className={styles.square}>
                <img className={styles.square} src={inst.icon} alt={""}></img>
              </div>
            </Col>
            <Col xs={12} sm={10} className={`pb-3`}>
              {inst.instrument_descr}
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>STRONA INTERNETOWA</strong>
              </div>
              <div>
                <a
                  href={`${inst.url}`}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  {inst.url.slice(
                    getPosition(inst.url, "/", 2) + 1,
                    getPosition(inst.url, "/", 3)
                  )}
                </a>
              </div>
            </Col>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <hr />
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>MAKSYMALNE DOFINANSOWANIE</strong>
              </div>
              <div>{inst.max} tys. euro</div>
            </Col>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <hr />
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col
              xs={12}
              sm={{ span: 10, offset: 2 }}
              className={styles.descrTitles}
            >
              <div>
                <strong>KOSZTY KWALIFIKOWANE</strong>
              </div>
              <div>{inst.qualifying_cost}</div>
            </Col>
            <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
              <hr />
            </Col>
          </Row>
        </div>
      );
    }
  }

  

  const clauses = []

  if (calculator.clause !== undefined) {
    for(const clause of calculator.clause) {
      
      clauses.push(
        <Row>
          <Col xs={12}>
            <p className={styles.clause}>{clause.clause}</p>
          </Col>
        </Row>
      )
    }
  }

  // console.log(clauses);
  

  let answersProgress: number =
    ((calculator.activePage + 3) * 100) / (calculator.numberOfPages + 2);

  return (
    <>
      <Modal.Body>
        <Container className={styles.container}>
          <Row className={"mt-3 mb-5"}>
            <Col xs={12} className={"text-center pb-3"}>
              <strong>Raport</strong>
            </Col>
            <Col xs={12}>
              <ProgressBar variant="info" now={answersProgress} />
            </Col>
          </Row>

          {/* STRATEGIE */}
          {calculator.strategy !== undefined && techDescriptions.length > 0 && calculator.altStrategy !== undefined ? (
            <>
              <Row className={"my-3"}>
                <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                  <div className={styles.fontBig}>
                    {calculator.strategy.strategy_name !== calculator.altStrategy.strategy_name ? <strong>Strategia główna</strong> : <strong>Strategia</strong>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                  <div className={styles.name}>{calculator?.strategy?.strategy_name}</div>
                </Col>
              </Row>
              <Row className={"my-3"}>
                <Col xs={12} sm={2} className={`${styles.justifier} pb-3`}>
                  <div className={styles.square}>
                    <img
                      className={styles.square}
                      src={calculator.strategy?.icon}
                      alt={""}
                    ></img>
                  </div>
                </Col>
                <Col xs={12} sm={10} className={`pb-3`}>
                  <div>{calculator.strategy.strategy_descr}</div>
                </Col>
              </Row>
            </>
          ) : calculator.problems.length > 0 ? (
            <Row className={"my-3"}>
              <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                <div>
                  Brak technologii rozwiązujących problem {calculator.problems[0].problem_name}.
                </div>
              </Col>
            </Row>
          ) : 
            <Row className={"my-3"}>
              <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                <div>
                  Niestety z podanych odpowiedzi nie da się ustalić problemu.
                </div>
              </Col>
            </Row>}
          {/* TECHNOLOGIE */}
          {techDescriptions}

          {/* STRATEGIA ALTERNATYWNA */}
          {calculator.altStrategy !== undefined &&
          calculator.problems.length > 1 &&
          calculator.altStrategy.strategy_name !== calculator.strategy?.strategy_name &&
          altTechDescriptions.length > 0 ? (
            <>
              <Row className={"my-3"}>
                <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                  <div className={styles.fontBig}>
                    <strong>Strategia alternatywna</strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                  <div className={styles.name}>{calculator.altStrategy.strategy_name}</div>
                </Col>
              </Row>
              <Row className={"my-3"}>
                <Col xs={12} sm={2} className={`${styles.justifier} pb-3`}>
                  <div className={styles.square}>
                    <img
                      className={styles.square}
                      src={calculator.altStrategy.icon}
                      alt={""}
                    ></img>
                  </div>
                </Col>
                <Col xs={12} sm={10} className={`pb-3`}>
                  {calculator?.altStrategy?.strategy_descr}
                </Col>
              </Row>
            </>
          ) : (
            <></>
            )}
          {/* TEHCNOLOGIE ALTERNATYWNE */}
          {altTechDescriptions}
          {/* INSTRUMENTY */}
          {instruments.length > 0 ? (
            <>
              <Row>
                <Col xs={12} sm={{ span: 10, offset: 2 }} className={`pb-3`}>
                  <div className={styles.fontBig}>
                    <strong>Dofinansowanie</strong>
                  </div>
                </Col>
              </Row>
              {instruments}
            </>
          ) : (
            <></>
            )}
            {clauses}
        </Container>
        <Row>
          <Col xs={12} className={styles.buttons}>
            <Button
              variant="light"
              className={styles.equalButtons}
              onClick={calculator?.stageBack}
              disabled={calculator.loading}
            >
              Poprzednia
            </Button>
            <Button
              variant="light"
              className={styles.equalButtons}
              onClick={calculator?.downloadSummary}
              disabled={calculator.loading}
            >
              Pobierz
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export interface SummaryPageProps {}

export default SummaryPage;
