import React from 'react'
import { divIcon } from 'leaflet';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { GeoAltFill } from 'react-bootstrap-icons';
import { renderToStaticMarkup } from 'react-dom/server';
import "leaflet/dist/leaflet.css";
import "./Map.css"

const location = {
  lat: 52.230900,
  lng: 21.021880,
}

const icon = renderToStaticMarkup(<GeoAltFill size={25} />)
const customIcon = divIcon({
  html: icon,
})

const Map = () => {
  return (
    <MapContainer style={{height: "25vh", width: "100%"}} center={location} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={location} icon={customIcon} />
      </MapContainer>
  )
}


export default Map