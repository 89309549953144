import useCalculator from "hooks/useCalculator";
import DetailsPage from "modalPages/CalculatorModal/DetailsPage";
import QuestionsPage from "modalPages/CalculatorModal/QuestionsPage";
import SummaryPage from "modalPages/CalculatorModal/SummaryPage";
import React from "react";

const CalculatorStageManager: React.FC<CalculatorStageManagerProps> = () => {
  const { calculator } = useCalculator();

  return (
    <>
      {calculator.stage === "questions" && <QuestionsPage />}
      {calculator.stage === "details" && <DetailsPage />}
      {calculator.stage === "summary" && <SummaryPage />}
    </>
  );
};

export interface CalculatorStageManagerProps {}

export default CalculatorStageManager;
