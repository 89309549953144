import { CalculatorApiProvider, ServiceApiResponse } from "hooks/useApiRequest";
import React, { FC, useReducer, useEffect } from "react";

const initialCalculatorValue: CalculatorPage[] = [
  {
    id: 0,
    problem: {
      id: 0,
      problem_descr: "",
      problem_name: "",
      strategy: 0,
    },
    is_active: false,
    scale1: "",
    scale2: "",
    scale1Answer: undefined,
    scale2Answer: undefined,
    question: "",
    weight: 0,
  },
];

type Problem = {
  id: number;
  problem_name: string;
  problem_descr: string;
  strategy: number;
};

type CalculatorPage = {
  id: number;
  problem: Problem;
  question: string;
  is_active: boolean;
  scale1: string;
  scale2: string;
  scale1Answer: number | undefined;
  scale2Answer: number | undefined;
  weight: number;
};

export type Branch = {
  id: number;
  branch: string;
};

export type Turnover = {
  id: number;
  turnover_size: string;
  max_turnover: string;
};

export type Clause = {
  id: number;
  clause: string;
}

export type Employment = {
  id: number;
  employment_size: string;
  max_employment: string;
};

export type Strategy = {
  id: number;
  strategy_name: string;
  strategy_descr: string;
  icon: string;
};

export type Technology = {
  id: number;
  tech_name: string;
  tech_descr: string;
  problem1: Problem;
  problem2: Problem;
  problem3: Problem;
  branch1: Branch;
  branch2: Branch;
  branch3: Branch;
  tech_cost: string;
  cost_descr: string;
  tech_profit: number;
  profit_descr: string;
  icon: string;
};

export type Technologies = {
  main: Technology[];
  alt: Technology[];
};

export type Instrument = {
  id: number;
  instrument_name: string;
  instrument_descr: string;
  url: string;
  instrument_benefits: string;
  max: string;
  qualifying_cost: string;
  icon: string;
};

type CalculatorStageType = "questions" | "details" | "summary";

export type Calculator = {
  activePage: number;
  stage: CalculatorStageType;
  answers: CalculatorPage[];
  availableBranches: Branch[];
  availableTurnovers: Turnover[];
  availableEmployments: Employment[];
  branches: number[] | undefined;
  employment: number | undefined;
  turnover: number | undefined;
  strategy: Strategy | undefined;
  altStrategy: Strategy | undefined;
  technologies: Technologies | undefined;
  instruments: Instrument[];
  clause: Clause[];
  problems: Problem[];
};

/**
 * Typ uzywany do komunikacji z komponentami.
 */
export type CalculatorInterface = {
  stage: CalculatorStageType;
  activePage: number;
  numberOfPages: number;
  id: number;
  question: string;
  is_active: boolean;
  setIs_active: (value: boolean) => void;
  scale1: string;
  scale2: string;
  availableBranches: Branch[];
  availableTurnovers: Turnover[];
  availableEmployments: Employment[];
  strategy: Strategy | undefined;
  altStrategy: Strategy | undefined;
  technologies: Technologies | undefined;
  instruments: Instrument[];
  branches: number[];
  scale1Answer: number | undefined;
  scale2Answer: number | undefined;
  clause: Clause[];
  problems: Problem[];
  nextPage: () => void;
  previousPage: () => void;
  setScale1Answer: (value: number) => void;
  setScale2Answer: (value: number) => void;
  setBranches: (value: number[]) => void;
  setEmployment: (value: number | undefined) => void;
  setTurnover: (value: number | undefined) => void;
  /**
   * Sends first part of calculator (questions) to API,
   * and if the response code from server is 200,
   * sets the calculator Stage to "details"
   */
  sendCompanyInfo: () => void;
  sendDetails: () => void;
  downloadSummary: () => void;
  employment: number | undefined;
  turnover: number | undefined;
  stageBack: () => void;
  error: string;
  loading: boolean;
};

type CalculatorActionWithNoParams = {
  type: "incrementPage" | "decrementPage" | "sendCompanyInfo";
};

type CalculatorActionWithNumber = {
  type: "setScale1Answer" | "setScale2Answer" | "setEmployment" | "setTurnover";
  payload: number;
};
type CalculatorActionWithNumberOrUndefined = {
  type: "setEmployment" | "setTurnover";
  payload: number | undefined;
};
type CalculatorActionWithNumberArray = {
  type: "setBranches";
  payload: number[];
};
type CalculatorActionWithBoolean = {
  type: "setIs_active";
  payload: boolean;
};
type CalculatorActionWithStage = {
  type: "setStage";
  payload: CalculatorStageType;
};
type CalculatorActionWithState = {
  type: "setState";
  payload: CalculatorPage[];
};
type CalculatorActionWithBranchesArray = {
  type: "setAvailableBranches";
  payload: Branch[];
};
type CalculatorActionWithTurnoversArray = {
  type: "setAvailableTurnovers";
  payload: Turnover[];
};
type CalculatorActionWithEmploymentsArray = {
  type: "setAvailableEmployments";
  payload: Employment[];
};
type CalculatorActionWithStrategy = {
  type: "setStrategy" | "setAlternativeStrategy";
  payload: Strategy;
};
type CalculatorActionWithTechnologies = {
  type: "setTechnologies";
  payload: Technologies;
};
type CalculatorActionWithInstrumentsArray = {
  type: "setInstruments";
  payload: Instrument[];
};
type CalculatorActionWithClauseArray = {
  type: "setClause";
  payload: Clause[];
}
type CalculatorActionWithProblemsArray = {
  type: "setProblems";
  payload: Problem[];
}
type CalculatorAction =
  | CalculatorActionWithNoParams
  | CalculatorActionWithNumber
  | CalculatorActionWithNumberArray
  | CalculatorActionWithState
  | CalculatorActionWithBoolean
  | CalculatorActionWithStage
  | CalculatorActionWithBranchesArray
  | CalculatorActionWithTurnoversArray
  | CalculatorActionWithEmploymentsArray
  | CalculatorActionWithNumberOrUndefined
  | CalculatorActionWithStrategy
  | CalculatorActionWithTechnologies
  | CalculatorActionWithInstrumentsArray
  | CalculatorActionWithClauseArray
  | CalculatorActionWithProblemsArray;

type CalculatorContextReducer = {
  activePage: number;
  incPage: () => void;
  decPage: () => void;
};

const initialCalculatorState: Calculator = {
  activePage: 0,
  stage: "questions",
  answers: initialCalculatorValue,
  branches: undefined,
  employment: undefined,
  turnover: undefined,
  availableBranches: [],
  availableTurnovers: [],
  availableEmployments: [],
  strategy: undefined,
  altStrategy: undefined,
  technologies: undefined,
  instruments: [],
  clause: [],
  problems: [],
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalculatorContextReducer = React.createContext<{
  calculator: CalculatorInterface;
} | null>(null);

function calculatorReducer(
  state: Calculator,
  action: CalculatorAction
): Calculator {
  switch (action.type) {
    case "setState": {
      const newState: CalculatorPage[] = action.payload.map(
        (item: CalculatorPage) => {
          return { ...item };
        }
      );
      return { ...state, answers: newState };
    }
    case "incrementPage": {
      if (state.activePage < state.answers.length - 1)
        return { ...state, activePage: state.activePage + 1 };
      return state;
    }
    case "decrementPage": {
      if (state.activePage > 0) {
        return { ...state, activePage: state.activePage - 1 };
      }
      return state;
    }
    case "setScale1Answer": {
      const newAnswers: CalculatorPage[] = state.answers;
      newAnswers[state.activePage] = {
        ...newAnswers[state.activePage],
        scale1Answer: action.payload ?? 0,
      };
      return { ...state, answers: newAnswers };
    }

    case "setScale2Answer": {
      const newAnswers: CalculatorPage[] = state.answers;
      newAnswers[state.activePage] = {
        ...newAnswers[state.activePage],
        scale2Answer: action.payload ?? 0,
      };
      return { ...state, answers: newAnswers };
    }
    case "setIs_active": {
      const newAnswers: CalculatorPage[] = state.answers;
      newAnswers[state.activePage] = {
        ...newAnswers[state.activePage],
        is_active: !!action.payload,
      };
      return { ...state, answers: newAnswers };
    }
    case "setStage": {
      return { ...state, stage: action.payload };
    }
    case "setBranches": {
      return { ...state, branches: action.payload };
    }
    case "setEmployment": {
      return { ...state, employment: action.payload };
    }
    case "setTurnover": {
      return { ...state, turnover: action.payload };
    }
    case "setAvailableBranches": {
      return { ...state, availableBranches: action.payload };
    }
    case "setAvailableTurnovers": {
      return { ...state, availableTurnovers: action.payload };
    }
    case "setAvailableEmployments": {
      return { ...state, availableEmployments: action.payload };
    }
    case "setStrategy": {
      return { ...state, strategy: action.payload };
    }
    case "setAlternativeStrategy": {
      return { ...state, altStrategy: action.payload };
    }
    case "setTechnologies": {
      return { ...state, technologies: action.payload };
    }
    case "setInstruments": {
      return { ...state, instruments: action.payload };
    }
    case "setClause": {
      return { ...state, clause: action.payload}
    }
    case "setProblems": {
      return { ...state, problems: action.payload}
    }
    default:
      return state;
  }
}

export const CalculatorReducerProvider: FC<BoxProps> = ({ children }) => {
  const {
    useGetCalculator,
    usePostCalculator,
    usePostCalculatorDetails,
    usePostSummaryDownload,
  } = CalculatorApiProvider;

  const [service, getCalculator] = useGetCalculator();
  const [saveCompanyInfoService, postCompanyInfo] = usePostCalculator();
  const [saveDetailsService, postCalculatorDetails] =
    usePostCalculatorDetails();
  const [downloadSummaryService, downloadSummary] = usePostSummaryDownload();

  const checkLoading = (): boolean => {
    return [
      service,
      saveCompanyInfoService,
      saveDetailsService,
      downloadSummaryService,
    ].some((x) => x.status === "loading");
  };

  const getError = (service: ServiceApiResponse): string | null => {
    if (service.status === "error") {
      return service.error.message;
    }
    return null;
  };

  const getErrorMessage = (): string | null => {
    return (
      getError(service) ??
      getError(saveCompanyInfoService) ??
      getError(saveDetailsService) ??
      getError(downloadSummaryService)
    );
  };

  const [state, dispatch] = useReducer<
    React.Reducer<Calculator, CalculatorAction>
  >(calculatorReducer, initialCalculatorState);

  useEffect(() => {
    getCalculator().then((response) => {
      if (response.status.code === 200) {
        dispatch({ type: "setState", payload: response.data });
      }
    });
  }, []);

  const calculator: CalculatorInterface = {
    activePage: state.activePage,
    stage: state.stage,
    numberOfPages: state.answers.length,
    question: state.answers[state.activePage].question,
    is_active: state.answers[state.activePage].is_active,
    setIs_active: (value: boolean) =>
      dispatch({ type: "setIs_active", payload: value }),
    id: state.answers[state.activePage].id,
    scale1: state.answers[state.activePage].scale1,
    scale2: state.answers[state.activePage].scale2,
    scale1Answer: state.answers[state.activePage].scale1Answer,
    scale2Answer: state.answers[state.activePage].scale2Answer,
    availableBranches: state.availableBranches,
    availableTurnovers: state.availableTurnovers,
    availableEmployments: state.availableEmployments,
    branches: state.branches ?? [],
    employment: state.employment,
    turnover: state.turnover,
    strategy: state.strategy,
    altStrategy: state.altStrategy,
    technologies: state.technologies,
    instruments: state.instruments,
    clause: state.clause,
    problems: state.problems,
    nextPage: () => dispatch({ type: "incrementPage" }),
    previousPage: () => dispatch({ type: "decrementPage" }),
    setScale1Answer: (value: number) =>
      dispatch({ type: "setScale1Answer", payload: value }),
    setScale2Answer: (value: number) =>
      dispatch({ type: "setScale2Answer", payload: value }),
    setBranches: (value: number[]) =>
      dispatch({ type: "setBranches", payload: value }),
    setEmployment: (value: number | undefined) =>
      dispatch({ type: "setEmployment", payload: value }),
    setTurnover: (value: number | undefined) =>
      dispatch({ type: "setTurnover", payload: value }),

    sendCompanyInfo: () => {
      postCompanyInfo(state).then((response) => {
        if (response.status.code === 200) {
          dispatch({ type: "setStage", payload: "details" });
          dispatch({
            type: "setAvailableBranches",
            payload: response.data.branches,
          });
          dispatch({
            type: "setAvailableTurnovers",
            payload: response.data.turnover,
          });
          dispatch({
            type: "setAvailableEmployments",
            payload: response.data.employment,
          });
          dispatch({
            type: "setClause",
            payload: response.data.clause,
          });
          dispatch({
            type: "setProblems",
            payload: response.data.problems,
          });
        }
      });
    },
    sendDetails: () => {
      postCalculatorDetails(state).then((response) => {
        if (response.status.code === 200) {
          dispatch({ type: "setStage", payload: "summary" });
          dispatch({ type: "setStrategy", payload: response.data.strategy });
          dispatch({
            type: "setAlternativeStrategy",
            payload: response.data["alt_strategy"],
          });
          dispatch({
            type: "setTechnologies",
            payload: response.data.technologies,
          });
          dispatch({
            type: "setInstruments",
            payload: response.data.instruments,
          });
          dispatch({
            type: "setClause",
            payload: response.data.clause,
          });
          dispatch({
            type: "setProblems",
            payload: response.data.problems,
          });
        }
      });
    },
    downloadSummary: () => {
      downloadSummary(state).then((response) => {
        dispatch({ type: "setStage", payload: "summary" });
      });
    },
    stageBack: () => {
      if (state.stage === "details")
        dispatch({ type: "setStage", payload: "questions" });
      if (state.stage === "summary")
        dispatch({ type: "setStage", payload: "details" });
    },
    error: getErrorMessage() ?? "",
    loading: checkLoading(),
  };

  return (
    <CalculatorContextReducer.Provider value={{ calculator }}>
      {children}
    </CalculatorContextReducer.Provider>
  );
};

type BoxProps = {
  children: React.ReactNode; // added type for children
};
