import React from "react";
import Form from "react-bootstrap/Form";
import styles from "./AnswerRadioButton.module.scss";

const AnswerRadioButton: React.FC<AnswerRadioButtonProps> = ({
  setIs_active,
  value,
}) => {
  return (
    <Form>
      <Form.Check
        type="radio"
        label="Tak"
        id="yes"
        value="yes"
        name="isActive"
        className={"mb-3 " + styles.answer}
        onClick={() => setIs_active(true)}
        checked={value === true}
      />
      <Form.Check
        type="radio"
        label="Nie"
        id="no"
        value="no"
        name="isActive"
        className={styles.answer}
        onClick={() => setIs_active(false)}
        checked={value === false}
      />
    </Form>
  );
};

export interface AnswerRadioButtonProps {
  setIs_active: (value: boolean) => void;
  value: boolean | undefined;
}

export default AnswerRadioButton;
