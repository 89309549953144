import { ModalProvider } from "./contexts/ModalContext";
import { CalculatorReducerProvider } from "./contexts/CalculatorContextReducer";
import Header from "./components/core/Header/Header";
import Content from "./components/core/Content/Content";
import Footer from "./components/core/Footer/Footer";
import "bootstrap/dist/css/bootstrap.css";
import ModalManager from "./core/ModalManager";
import "./App.css";

function App() {
  return (
    <>
      <ModalProvider>
        <CalculatorReducerProvider>
          <div className="App">
            <Header />
            <Content />
            <Footer />
            <ModalManager />
          </div>
        </CalculatorReducerProvider>
      </ModalProvider>
    </>
  );
}

export default App;
