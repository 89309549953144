import {
  Branch,
  Employment,
  Turnover,
} from "contexts/CalculatorContextReducer";
import useCalculator from "hooks/useCalculator";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";
import { MultiSelect, Option } from "react-multi-select-component";
import styles from "./DetailsPage.module.scss";

interface IDefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: any;
}

const CustomItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: IDefaultItemRendererProps) => (
  <div
    className={`item-renderer ${disabled ? "disabled" : ""}`}
    onClick={onClick}
  >
    <span>{option.label}</span>
  </div>
);

const DetailsPage: React.FC<DetailsPageProps> = () => {
  const { calculator } = useCalculator();

  let answersProgress: number =
    ((calculator.activePage + 2) * 100) / (calculator.numberOfPages + 2);

  return (
    <>
      <Modal.Body>
        <Container>
          <Row className={"mt-3 mb-5"}>
            <Col xs={12} className={"text-center pb-3"}>
              <strong>
                Strona {calculator.activePage + 2} z{" "}
                {calculator.numberOfPages + 1}
              </strong>
            </Col>
            <Col xs={12}>
              <ProgressBar variant="info" now={answersProgress} />
            </Col>
          </Row>
          {calculator.availableBranches.length !== 0 ? 
          <>
            <Row>
              <Col xs={12} className={"my-3"}>
                Wybierz działy przedsiębiorstwa, w których ma być przeprowadzona
                cyfryzacja
              </Col>
              <Col xs={12}>
                <MultiSelect
                  overrideStrings={{
                    allItemsAreSelected: "Wybrano wszystkie działy",
                    selectSomeItems: "Wybierz...",
                  }}
                  options={calculator.availableBranches.map(
                    (item: Branch): Option => {
                      return { value: item.id, label: item.branch };
                    }
                  )}
                  value={calculator.branches.map((item: number): Option => {
                    return {
                      value: item,
                      label:
                        calculator.availableBranches.find((x) => x.id === item)
                          ?.branch ?? "",
                    };
                  })}
                  onChange={(items: Option[]) => {
                    calculator.setBranches(
                      items.map((item: Option): number => {
                        return item.value;
                      })
                    );
                  }}
                  labelledBy="Wybierz"
                  hasSelectAll={false}
                  disableSearch={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={"mt-5 mb-3"}>
                Wybierz liczbę osób zatrudnionych w przedsiębiorstwie
              </Col>
              <Col xs={12}>
                <MultiSelect
                  overrideStrings={{
                    allItemsAreSelected: "Wybrano wszystkie działy",
                    selectSomeItems: "Wybierz...",
                  }}
                  ItemRenderer={CustomItemRenderer}
                  closeOnChangedValue
                  options={calculator.availableEmployments.map(
                    (item: Employment): Option => {
                      return {
                        value: item.id,
                        label:
                          item.max_employment,
                      };
                    }
                  )}
                  value={
                    !!calculator.employment
                      ? [
                          {
                            value: calculator.employment ?? 0,
                            label:
                              calculator.availableEmployments.find(
                                (x) => x.id === calculator.employment
                              )?.max_employment ?? "",
                          },
                        ]
                      : []
                  }
                  onChange={(items: Option[]) => {
                    if (!!items.length) {
                      calculator.setEmployment(items[items.length - 1].value);
                    } else {
                      calculator.setEmployment(undefined);
                    }
                  }}
                  labelledBy="Wybierz"
                  hasSelectAll={false}
                  disableSearch={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={"mt-5 mb-3"}>
                Maksymalny obrót przedsiębiorstwa (w mln zł)
              </Col>
              <Col xs={12}>
                <MultiSelect
                  overrideStrings={{
                    allItemsAreSelected: "Wybrano wszystkie działy",
                    selectSomeItems: "Wybierz...",
                  }}
                  className="mb-4"
                  closeOnChangedValue
                  ItemRenderer={CustomItemRenderer}
                  options={calculator.availableTurnovers.map(
                    (item: Turnover): Option => {
                      return {
                        value: item.id,
                        label:
                          item.max_turnover,
                      };
                    }
                  )}
                  value={
                    !!calculator.turnover
                      ? [
                          {
                            value: calculator.turnover ?? 0,
                            label:
                              calculator.availableTurnovers.find(
                                (x) => x.id === calculator.turnover
                              )?.max_turnover ?? "",
                          },
                        ]
                      : []
                  }
                  onChange={(items: Option[]) => {
                    if (!!items.length) {
                      calculator.setTurnover(items[items.length - 1].value);
                    } else {
                      calculator.setTurnover(undefined);
                    }
                  }}
                  labelledBy="Wybierz"
                  hasSelectAll={false}
                  disableSearch={true}
                />
              </Col>
            </Row>
          </> :
          <Row>
            <Col>
                <h5>Niestety dla podanych odpowiedzi nie występują żadne rozwiązania.</h5>
            </Col>
          </Row>
          }
        </Container>
        <Row>
          <Col className={styles.buttons} xs={12} md={{span: 10, offset: 1}}>
          <Button
            className={styles.equalButtons}
            variant="light"
            onClick={calculator?.stageBack}
            disabled={calculator.loading}
          >
            Poprzednia
          </Button>
          {calculator.availableBranches.length !== 0 ?
          <Button
            variant="light"
            className={styles.equalButtons}
            onClick={calculator?.sendDetails}
            disabled={
              !!!calculator.branches.length ||
              !!!calculator.employment ||
              !!!calculator.turnover ||
              calculator.loading
            }
          >
            Raport
          </Button>
          : <></>
          }   
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export interface DetailsPageProps {}

export default DetailsPage;
