const BASE_URL = process.env.REACT_APP_API_URL;

const getApiUrl = (path: string): string => {
  if (!!BASE_URL) return `${BASE_URL}/api/${path}`;
  else
    return (
      window.location.protocol +
      "//" +
      window.location.hostname +
      "/api/" +
      path
    );
};

export default getApiUrl;
