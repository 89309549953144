import React, { FC, useState, createContext } from "react";

export type Modals = "calculator" | "about" | "contact" | undefined;

type ModalContext = {
  activeModal: Modals;
  setActiveModal: (element: Modals) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModalContext = createContext<ModalContext>({} as ModalContext);

export const ModalProvider: FC<BoxProps> = ({ children }) => {
  const [aModal, setaModal] = useState<Modals>();
  const setActiveModal = (element: Modals) => {
    setaModal(element);
  };

  return (
    <ModalContext.Provider value={{ activeModal: aModal, setActiveModal }}>
      {children}
    </ModalContext.Provider>
  );
};

type BoxProps = {
  children: React.ReactNode; // added type for children
};
