import AnswerRadioButton from "elements/AnswerRadioButton";
import AnswerScale from "elements/AnswerScale";
import useCalculator from "hooks/useCalculator";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import styles from "./QuestionsPage.module.scss";

const QuestionsPage: React.FC<QuestionsPageProps> = () => {
  const { calculator } = useCalculator();

  let answersProgress: number =
    ((calculator.activePage + 1) * 100) / (calculator.numberOfPages + 2);

  let buttonPrevious;
  let buttonNext;
  let buttonReport;

  if (calculator.activePage > 0) {
    buttonPrevious = (
      <Button
        variant="light"
        className={styles.equalButtons}
        onClick={calculator?.previousPage}
        disabled={calculator.loading}
      >
        Poprzednia
      </Button>
    );
  }
  if (
    calculator.is_active === false ||
    (calculator.is_active === true &&
      calculator.scale1Answer !== undefined &&
      calculator.scale2Answer !== undefined)
  ) {
    if (calculator.activePage + 1 === calculator.numberOfPages) {
      buttonReport = (
        <Button
          className={styles.equalButtons}
          variant="light"
          onClick={calculator?.sendCompanyInfo}
          disabled={calculator.loading}
        >
          Następna
        </Button>
      );
    } else {
      buttonNext = (
        <Button variant="light" className={styles.equalButtons} onClick={calculator?.nextPage}>
          Następna
        </Button>
      );
    }
  } else {
    if (calculator.activePage + 1 === calculator.numberOfPages) {
      buttonReport = (
        <Button variant="light" className={styles.equalButtons} disabled>
          Następna
        </Button>
      );
    } else {
      buttonNext = (
        <Button variant="light" className={styles.equalButtons} disabled>
          Następna
        </Button>
      );
    }
  }

  return (
    <>
      <Modal.Body className={styles.modalBody}>
        <Container>
          <Row className={"mt-3 mb-5"}>
            <Col xs={12} className={"text-center pb-3"}>
              <strong>
                Strona {calculator.activePage + 1} z{" "}
                {calculator.numberOfPages + 1}
              </strong>
            </Col>
            <Col xs={12}>
              <ProgressBar variant="info" now={answersProgress} />
            </Col>
          </Row>
          <ol className={styles.olList}>
            <Row className={"my-3"}>
              <Col xs={12}>
                <li>{calculator.question}</li>
              </Col>
            </Row>
            <Row className={"my-3"}>
              <Col xs={12}>
                <AnswerRadioButton
                  setIs_active={calculator.setIs_active}
                  value={calculator.is_active}
                />
              </Col>
            </Row>
            {calculator.is_active === true && (
              <>
                <Row className={"mt-5 mb-3"}>
                  <Col xs={12}>
                    <li>{calculator.scale1}</li>
                  </Col>
                </Row>
                <Row className={"my-3"}>
                  <Col xs={12}>
                    <AnswerScale
                      scaleAnswer={calculator.scale1Answer}
                      type={"scale1"}
                      setScaleAnswer={calculator.setScale1Answer}
                    />
                  </Col>
                </Row>
                <Row className={"mt-5 mb-3"}>
                  <Col xs={12}>
                    <li>{calculator.scale2}</li>
                  </Col>
                </Row>
                <Row className={"my-3"}>
                  <Col xs={12}>
                    <AnswerScale
                      scaleAnswer={calculator.scale2Answer}
                      type={"scale2"}
                      setScaleAnswer={calculator.setScale2Answer}
                    />
                  </Col>
                </Row>
              </>
            )}
          </ol>
        </Container>
        <Row>
          <Col className={styles.buttons} xs={12} md={{span: 10, offset: 1}}>
            {buttonPrevious}
            {buttonNext}
            {buttonReport}
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export interface QuestionsPageProps {}

export default QuestionsPage;
