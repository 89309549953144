import { Calculator } from "contexts/CalculatorContextReducer";
import getApiUrl from "helpers/getApiUrl";
import { useState } from "react";
import getCookie from "helpers/getCSFR";

type ApiServerResponse = {
  data: any;
  status: {
    code: number;
    status: string;
    message: string;
  };
};

export type CustomError = {
  error?: Error;
  message: string;
  type: "SESSION_EXPIRED" | "VALIDATION ERROR" | "BAD_REQUEST" | "UNKNOWN";
};

interface ServiceInit {
  status: "init";
}
interface ServiceLoading {
  status: "loading";
}
interface ServiceLoaded {
  status: "loaded";
  payload: ApiServerResponse;
}
interface ServiceError {
  status: "error";
  payload: ApiServerResponse;
  error: CustomError;
}
export type ServiceApiResponse =
  | ServiceInit
  | ServiceLoading
  | ServiceLoaded
  | ServiceError;

export type useApiRequestResponse = [
  ServiceApiResponse,
  (data: any, api: string, method: string) => Promise<any>
];

/// typ dla opakowania i zwracania wlasnego bledu z typowaniem TS

const useApiRequest = <T>(): useApiRequestResponse => {
  const [service, setService] = useState<ServiceApiResponse>({
    status: "init",
  });

  const postValue = (data: T, api: string, method: string) => {
    setService({ status: "loading" });

    const csrftoken = getCookie("csrftoken");
    console.log(csrftoken);
    

    let hdrs: any = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (csrftoken !== null) {
      hdrs["X-CSRFToken"] = csrftoken;
    }
    console.log(csrftoken);
    const fetchSettings: RequestInit = {
      method: method,
      // credentials: "include",
      headers: hdrs,
    };

    if (method !== "GET") {
      fetchSettings.body = JSON.stringify(data);
    }

    return new Promise((resolve, reject) => {
      fetch(api, fetchSettings)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.status.code === 400) {
            setService({
              status: "error",
              payload: response,

              error: {
                type: "BAD_REQUEST",
                error: undefined,
                message: response.status.message,
              },
            });
          } else {
            setService({ status: "loaded", payload: response });
          }

          resolve(response);
        })
        .catch((e) => {
          setService({
            status: "error",
            payload: {
              data: {},
              status: { code: 400, status: "Error", message: "Wystąpił błąd" },
            },
            error: {
              message: "Wystąpił błąd",
              error: e,
              type: "UNKNOWN",
            },
          });
          return undefined;
          // reject(error);
        });
    });
  };

  return [service, postValue];
};

const useGetCalculator = (): [ServiceApiResponse, () => Promise<any>] => {
  const [service, getCalculator] = useApiRequest();

  const getValue = (): Promise<any> => {
    return getCalculator({}, getApiUrl("questions/"), "GET");
  };

  return [service, getValue];
};

const usePostCalculator = (): [
  ServiceApiResponse,
  (calculator: Calculator) => Promise<any>
] => {
  const [service, getCalculator] = useApiRequest();

  const getValue = (calculator: Calculator): Promise<any> => {
    return getCalculator(calculator, getApiUrl("company-info/"), "POST");
  };

  return [service, getValue];
};

const usePostCalculatorDetails = (): [
  ServiceApiResponse,
  (calculator: Calculator) => Promise<any>
] => {
  const [service, getCalculator] = useApiRequest();

  const getValue = (calculator: Calculator): Promise<any> => {
    return getCalculator(calculator, getApiUrl("summary/"), "POST");
  };

  return [service, getValue];
};

const usePostSummaryDownload = (): [
  ServiceApiResponse,
  (calculator: Calculator) => Promise<any>
] => {
  const [service, setService] = useState<ServiceApiResponse>({
    status: "init",
  });

  const getValue = (calculator: Calculator): Promise<any> => {
    setService({ status: "loading" });

    const csrftoken = getCookie("csrftoken");

    let hdrs: any = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    console.log(csrftoken);
    
    
    if (csrftoken !== null) {
      hdrs["X-CSRFToken"] = csrftoken;
    }
    const fetchSettings: RequestInit = {
      method: "POST",
      // credentials: "include",
      headers: hdrs,
      body: JSON.stringify(calculator),
    };

    return fetch(getApiUrl("summary-download/"), fetchSettings)
      .then((response)=> response.blob())
      // .then((response) => {
      //   const myBlob = response
      //   const filename = response.headers.get("Content-Disposition")
      //   const ret = {
      //     'blob': myBlob.blob(),
      //     'filename': filename
      //   }
      //   return myBlob.blob()
      // })
      .then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        const pdfWindow = window.open();
        if (!!pdfWindow) pdfWindow.location.href = objectURL;
        setService({
          status: "loaded",
          payload: {
            data: {},
            status: { code: 200, message: "", status: "loaded" },
          },
        });
      })
      .catch((e) => {
        setService({
          status: "error",
          payload: {
            data: {},
            status: { code: 400, status: "Error", message: "Wystąpił błąd" },
          },
          error: {
            message: "Wystąpił błąd",
            error: e,
            type: "UNKNOWN",
          },
        });
        // reject(error);
      });
  };

  return [service, getValue];
};

export const CalculatorApiProvider = {
  useGetCalculator: useGetCalculator,
  usePostCalculator: usePostCalculator,
  usePostCalculatorDetails: usePostCalculatorDetails,
  usePostSummaryDownload: usePostSummaryDownload,
};

export default CalculatorApiProvider;
