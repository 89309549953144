import React from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { ModalContext } from "../../contexts/ModalContext";
import styles from "./AboutModal.module.scss";
import CloseButton from 'react-bootstrap/CloseButton';

const AboutModal: React.FC<AboutModalProps> = () => {
  const { setActiveModal } = React.useContext(ModalContext);

  const handleHide = () => {
    setActiveModal(undefined);
  };

  return (
    <Modal
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={true}
      size={'xl'}
      keyboard={false}
      onHide={handleHide}
      centered
    >
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title><strong>O kalkulatorze</strong></Modal.Title>
        <CloseButton className={styles.clsBtn} onClick={handleHide}/>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col xs={12}>
            <p>
              Kalkulator jest przeznaczony dla{" "}
              <strong>pracowników i właścicieli firm</strong> z branży
              produkcyjnej, handlowej i usługowej - osób zainteresowanych
              wdrożeniem rozwiązań cyfrowych w firmach, w których pracują lub
              których są właścicielami.
            </p>
            <p>Działanie kalkulatora polega na:</p>
            <ol>
              <li>
                Zidentyfikowaniu głównych <strong>problemów</strong> w firmie na
                podstawie <strong>pytań kwestionariuszowych</strong>
              </li>
              <li>
                Określeniu rekomendowanej <strong>strategii cyfrowej</strong>,
                która może stanowić odpowiedź na zidentyfikowane problemy
              </li>
              <li>
                Stworzeniu <strong>raportu</strong> z opisem rekomendowanej
                strategii i powiązanych z nią technologii cyfrowych
              </li>
            </ol>
            <p>
              <strong>Problemy</strong> zostały wybrane na podstawie wywiadów
              przeprowadzonych z ekspertami doradzającymi w procesie
              transformacji cyfrowych oraz literatury. Z problemów wymienianych
              przez ekspertów wybrano 20 najbardziej typowych.
            </p>
            <p>
              <strong>Pytania kwestionariuszowe</strong> są ułożone w grupy po
              trzy pytania. Pierwsze pytanie to pytanie o obecność danego
              problemu w firmie. Drugie pytanie to pytanie o nasilenie danego
              problemu w firmie. Trzecie pytanie to pytanie o znaczenie
              (ważność) danego problemu dla firmy. Każda grupa trzech pytań jest
              powiązana z konkretnym problemem.
            </p>
            <p>
              Po udzieleniu odpowiedzi na pytania kwestionariuszowe kalkulator
              dobiera <strong>strategię cyfryzacji</strong>, czyli sposób
              postępowania pozwalający maksymalnie wykorzystać możliwości
              kryjące się w technologiach w celu rozwiązania zidentyfikowanych
              problemów. Kalkulator zawiera cztery podstawowe strategie
              cyfryzacji: automatyzacja pracy, gromadzenia i przetwarzania
              danych, rozwój kooperacji i algorytmizacji decyzji.
            </p>
            <p>
              Każda strategia jest powiązana z technologiami.{" "}
              <strong>Technologie</strong> zostały wybrane na podstawie wywiadów
              z kolejną grupą ekspertów. Każda technologia jest powiązana z
              problemami, które może rozwiązać i działami organizacji, w których
              znajduje zastosowanie. Opis technologii zawiera przykładowy poziom
              kosztów jej zakupu lub wdrożenia, opis kosztów związanych z daną
              technologią oraz opis korzyści jakie określili eksperci możliwych
              do uzyskania w wyniku zastosowania danego rozwiązania (np.
              oszczędność czasu, obniżenie kosztów, wzrost wydajności).
            </p>
            <p>
              Kalkulator tworzy i wyświetla <strong>raport</strong>, który można
              pobrać bezpośrednio ze strony, bez podawania danych kontaktowych.
            </p>
            <p>
              Kalkulator został opracowany przy współpracy z Business-Designers
              na podstawie wywiadów z ekspertami doradzającymi w procesie
              transformacji cyfrowej oraz literatury.
            </p>
            <p>
              Korzystając z kalkulatora Użytkownik przyjmuje do wiadomości i akceptuje,
               iż wyniki uzyskane (w tym przesłane w raporcie) przy pomocy tej aplikacji
                mają charakter jedynie informacyjny i szacunkowy, a także że ARP S.A. nie
                 ponosi odpowiedzialności za działania, decyzje podjęte na podstawie uzyskanych 
                 przy pomocy kalkulatora wyników i informacji.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={styles.list}>
            <h2 className={styles.sectionTitle}>Bibliografia:</h2>
            <ol>
              <li>
                9lenses, Falconi K., Digital Transformation Defined. Findings
                From 10 Industry Thought Leaders,{" "}
                <a
                  href="https://www.9lenses.com/digital-transformation-definition/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.9lenses.com/digital-transformation-definition/
                </a>
                , dostęp 04.09.2021.
              </li>
              <li>
                Accenture, [za:] Digital Adoption, Accenture Digital
                Transformation: Top Insights And Lessons,{" "}
                <a
                  href="https://www.digital-adoption.com/accenture-digital-transformation/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.digital-adoption.com/accenture-digital-transformation/
                </a>
                , listopad 2019.
              </li>
              <li>
                BDO Digital (2020), Building Tomorrow’s Business: How The Middle
                Market Is Tackling Disruption Today. 2020 Digital Transformation
                Survey,{" "}
                <a
                  href="https://www.bdo.com/getmedia/42098cf7-9b26-41f8-9270-cb32ee1e78f8/BDO-Digital_2020-Digital-Transformation-Survey_Web.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.bdo.com/getmedia/42098cf7-9b26-41f8-9270-cb32ee1e78f8/BDO-Digital_2020-Digital-Transformation-Survey_Web.pdf
                </a>
                , 2020 Digital Transformation Survey.
              </li>
              <li>
                Capgemini, Digital Mastery 2020: How organizations have
                progressed in their digital transformations over the past two
                years,{" "}
                <a
                  href="https://www.capgemini.com/pl-pl/wp-content/uploads/sites/15/2021/01/Infographic_Digital-Mastery.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.capgemini.com/pl-pl/wp-content/uploads/sites/15/2021/01/Infographic_Digital-Mastery.pdf
                </a>
                .
              </li>
              <li>
                Capgemini, Transformacja cyfrowa – konieczność, nie moda,{" "}
                <a
                  href="https://www.capgemini.com/pl-pl/transformacja-cyfrowa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.capgemini.com/pl-pl/transformacja-cyfrowa/
                </a>
                , dostęp 04.09.2021.
              </li>
              <li>
                Capgemini, Wyzwania i szanse cyfrowej transformacji w ciągu
                ostatnich lat,{" "}
                <a
                  href="https://capgeminipolska.prowly.com/129763-wyzwania-i-szanse-cyfrowej-transformacji-w-ciagu-ostatnich-lat"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://capgeminipolska.prowly.com/129763-wyzwania-i-szanse-cyfrowej-transformacji-w-ciagu-ostatnich-lat
                </a>
                , 25.02.2021.
              </li>
              <li>
                CIO, Boulton C., What is digital transformation? A necessary
                disruption,{" "}
                <a
                  href="https://www.cio.com/article/3211428/what-is-digital-transformation-a-necessary-disruption.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.cio.com/article/3211428/what-is-digital-transformation-a-necessary-disruption.html
                </a>
                , 24.06.2021.
              </li>
              <li>
                ComputerWorld, Wtulich P., Cyfrowa transformacja... od podstaw,{" "}
                <a
                  href="https://www.computerworld.pl/news/Cyfrowa-transformacja-od-podstaw,406745.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.computerworld.pl/news/Cyfrowa-transformacja-od-podstaw,406745.html
                </a>
                , 27.11.2016.
              </li>
              <li>
                Deloitte (2015), Industry 4.0. Challenges and solutions for the
                digital transformation and use of exponential technologies,{" "}
                <a
                  href="https://www2.deloitte.com/content/dam/Deloitte/ch/Documents/manufacturing/ch-en-manufacturing-industry-4-0-24102014.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www2.deloitte.com/content/dam/Deloitte/ch/Documents/manufacturing/ch-en-manufacturing-industry-4-0-24102014.pdf
                </a>
                .
              </li>
              <li>
                Digital Shapers, PwC, 13 faktów o transformacji cyfrowej...
                czyli wszystko co chciałbyś wiedzieć o cyfryzacji, ale bałeś się
                zapytać, digitalpoland,{" "}
                <a
                  href="https://www.digitalpoland.org/assets/publications/13-tez-digital-shapers/13-faktow-o-transformacji-cyfrowej-digital-shapers.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.digitalpoland.org/assets/publications/13-tez-digital-shapers/13-faktow-o-transformacji-cyfrowej-digital-shapers.pdf
                </a>
                , październik 2020.
              </li>
              <li>
                Forbes, Bloomberg J., Digitization, Digitalization, And Digital
                Transformation: Confuse Them At Your Peril,{" "}
                <a
                  href="https://www.forbes.com/sites/jasonbloomberg/2018/04/29/digitization-digitalization-and-digital-transformation-confuse-them-at-your-peril/?sh=5f68aca72f2c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.forbes.com/sites/jasonbloomberg/2018/04/29/digitization-digitalization-and-digital-transformation-confuse-them-at-your-peril/?sh=5f68aca72f2c
                </a>
                , 29.04.2018.
              </li>
              <li>
                Gartner, Information Technology, Gartner Glossary - Digital
                Transformation{" "}
                <a
                  href="https://www.gartner.com/en/information-technology/glossary/digital-transformation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.gartner.com/en/information-technology/glossary/digital-transformation
                </a>
                , dostęp 03.09.2021.
              </li>
              <li>
                Google Trends,{" "}
                <a
                  href="https://trends.google.com/trends/explore?date=all&q=digital%20transformation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://trends.google.com/trends/explore?date=all&q=digital%20transformation
                </a>
                , dostęp 03.09.2021.
              </li>
              <li>
                Hausberg J.P., Liere‑Netheler K., Packmohr S., Pakura S.,
                Vogelsang K. (2019), Research Streams on Digital Transformation
                from a Holistic Business Perspective: A Systematic Literature
                Review and Citation Network Analysis, „Journal of Business
                Economics”, t. 89, s. 931–963.
              </li>
              <li>
                Helmy Ismail Abdelaal M., Khater M., Zaki M. (2018). Digital
                Business Transformation and Strategy: What Do We Know So Far?
                University of Cambridge, DOI: 10.13140/RG.2.2.36492.62086{" "}
              </li>
              <li>
                IBM, Pietrzak P, Transformacja cyfrowa – wprowadzenie. Część 1,{" "}
                <a
                  href="https://www.ibm.com/blogs/ibm-poland/transformacja-cyfrowa-wprowadzenie-czesc-1/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ibm.com/blogs/ibm-poland/transformacja-cyfrowa-wprowadzenie-czesc-1/
                </a>
                , 24.04.2019.
              </li>
              <li>
                ICAN Institute, Nosalska K., 5 kluczowych obszarów cyfrowej
                transformacji,{" "}
                <a
                  href="https://www.ican.pl/b/5-kluczowych-obszarow-cyfrowej-transformacji/PeHmjT1mp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ican.pl/b/5-kluczowych-obszarow-cyfrowej-transformacji/PeHmjT1mp
                </a>
                , 22.01.2018.
              </li>
              <li>
                McKinsey & Company, Unlocking success in digital
                transformations,{" "}
                <a
                  href="https://www.mckinsey.com/business-functions/organization/our-insights/unlocking-success-in-digital-transformations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.mckinsey.com/business-functions/organization/our-insights/unlocking-success-in-digital-transformations
                </a>
                , 29.10.2021.
              </li>
              <li>
                Morakanyane R., Grace A., O'Reilly P. (2017). Conceptualizing
                Digital Transformation in Business Organizations: A Systematic
                Review of Literature. 10.18690/978-961-286-043-1.30,{" "}
                <a
                  href="https://www.researchgate.net/publication/321805933_Conceptualizing_Digital_Transformation_in_Business_Organizations_A_Systematic_Review_of_Literature"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.researchgate.net/publication/321805933_Conceptualizing_Digital_Transformation_in_Business_Organizations_A_Systematic_Review_of_Literature
                </a>
                .
              </li>
              <li>
                Platforma Przemysłu Przyszłości, Kaczmarek-Kurczak P.,
                Przeczytaj, jak menadżer powinien działać w warunkach cyfrowej
                zmiany,{" "}
                <a
                  href="https://przemyslprzyszlosci.gov.pl/przeczytaj-co-powinien-robic-menadzer-odpowiedzialny-za-cyfrowa-zmiane/?gclid=CjwKCAjwj8eJBhA5EiwAg3z0m6_xmYOPDNVn4JVtnnXucxrDivi-JLIXMlr7UG-GzvWMSDjmwUnEOxoC8TIQAvD_BwE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://przemyslprzyszlosci.gov.pl/przeczytaj-co-powinien-robic-menadzer-odpowiedzialny-za-cyfrowa-zmiane/?gclid=CjwKCAjwj8eJBhA5EiwAg3z0m6_xmYOPDNVn4JVtnnXucxrDivi-JLIXMlr7UG-GzvWMSDjmwUnEOxoC8TIQAvD_BwE
                </a>
                , 08.07.2020.
              </li>
              <li>
                PwC (2017), Przemysł 4.0 czyli wyzwania współczesnej produkcji,{" "}
                <a
                  href="https://przemysl-40.pl/wp-content/uploads/2017-Przemysl40-czyli-wyzwania-wspo%C5%82czesnej-produkcji.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://przemysl-40.pl/wp-content/uploads/2017-Przemysl40-czyli-wyzwania-wspo%C5%82czesnej-produkcji.pdf
                </a>
                .
              </li>
              <li>
                Reis J., Amorim M., Melão N., Matos P. (2018) Digital
                Transformation: A Literature Review and Guidelines for Future
                Research. In: Rocha Á., Adeli H., Reis L.P., Costanzo S. (eds)
                Trends and Advances in Information Systems and Technologies.
                WorldCIST'18 2018. Advances in Intelligent Systems and
                Computing, vol 745. Springer, Cham.{" "}
                <a
                  href="https://doi.org/10.1007/978-3-319-77703-0_41"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://doi.org/10.1007/978-3-319-77703-0_41
                </a>
                .
              </li>
              <li>
                Rzeczpospolita (rp.pl), Zagrożenie dla nieprzygotowanych, szansa
                dla konkurencji. Cyfrowa transformacja w przedsiębiorstwach,{" "}
                <a
                  href="https://www.rp.pl/biznes/art646311-zagrozenie-dla-nieprzygotowanych-szansa-dla-konkurencji-cyfrowa-transformacja-w-przedsiebiorstwach"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.rp.pl/biznes/art646311-zagrozenie-dla-nieprzygotowanych-szansa-dla-konkurencji-cyfrowa-transformacja-w-przedsiebiorstwach
                </a>
                , 29.06.2020.
              </li>
              <li>
                Salesforce, What Is Digital Transformation?,{" "}
                <a
                  href="https://www.salesforce.com/products/platform/what-is-digital-transformation/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.salesforce.com/products/platform/what-is-digital-transformation/
                </a>
                , dostęp 04.09.2021.
              </li>
              <li>
                SAS, Transformacja cyfrowa. Czym jest i dlaczego ma znaczenie,{" "}
                <a
                  href="https://www.sas.com/pl_pl/insights/data-management/digital-transformation.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.sas.com/pl_pl/insights/data-management/digital-transformation.html
                </a>
                , dostęp 03.09.2021.
              </li>
            </ol>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export interface AboutModalProps {}

export default AboutModal;
