import React, { useState, useRef, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import CloseButton from "react-bootstrap/CloseButton";
import { ModalContext } from "../../contexts/ModalContext";
import styles from "./ContactModal.module.scss";
import Map from "../../elements/Map";
import ReCAPTCHA from "react-google-recaptcha";
import getApiUrl from "helpers/getApiUrl";
import getCookie from "helpers/getCSFR";

let token: string | undefined;

const getToken = (token: string) => {
  return token;
};

const ContactModal: React.FC<ContactModalProps> = () => {
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/questions/`)
  }, [])

  const { setActiveModal } = React.useContext(ModalContext);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [showForm, setShowForm] = useState<boolean>(true);

  const nameRef = useRef<any>(null);
  const emailRef = useRef<any>(null);
  const messageRef = useRef<any>(null);
  const captchaRef = useRef<any>(null);
  const csrftoken = getCookie("csrftoken");
  const handleHide = () => {
    setActiveModal(undefined);
    setShowAlert(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    captchaRef.current.reset();

    const data = {
      "email": emailRef.current.value,
      "name": nameRef.current.value,
      "message": messageRef.current.value,
      "token": token
    };
    // if (csrftoken !== null) {
    fetch(getApiUrl("validate-captcha/"), {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        // "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      const code = response.status;
      if (code >= 200 && code < 300) {
        setShowForm(false);
        messageRef.current.value = "";
        emailRef.current.value = "";
        setAlertMessage("Wiadomość została wysłana");
        setShowAlert(true);
        console.log(response);
      } else if (code >= 300) {
        setShowForm(true);
        setAlertMessage("Wiadomość nie została wysłana");
        setShowAlert(true);
        console.log(response);
      } else {
        console.log(response);
      }
      });
    

    token = undefined;
  };

  const validator = () => {
    token = getToken(captchaRef.current.getValue());
  };

  const changer = () => {
    if (
      nameRef.current.value !== "" &&
      messageRef.current.value !== "" &&
      emailRef.current.value !== "" &&
      token !== undefined
    ) {
      setDisabled((disabled) => false);
    } else {
      setDisabled((disabled) => true);
    }
  };

  return (
    <Modal
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={true}
      size={"xl"}
      keyboard={false}
      onHide={handleHide}
      centered
    >
      <Modal.Header className={styles.header}>
        <Modal.Title><strong>Kontakt</strong></Modal.Title>
        <CloseButton className={styles.clsBtn} onClick={handleHide}/>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <Row>
          <Col xs={12}>
            <Map />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className={"mt-3 px-3"}>
              <p>Agencja Rozwoju Przemysłu S.A.</p>
              <p>ul. Nowy Świat 6/12</p>
              <p>00-400 Warszawa</p>
              <p>Centrum Bankowo Finansowe – wejście „C”</p>
              <p>
                e-mail:&nbsp;
                <a href="mailto:kalkulator@arp.pl">kalkulator@arp.pl</a>
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form
              className={styles.formBody}
              onSubmit={(event) => handleSubmit(event)}
            >
              {showForm ? (
                <>
                <Row>
                  <Col xs={6}>
                    <Form.Group> 
                      <Form.Control
                      className={styles.formInputs}
                      type="name"
                      placeholder="Imię i nazwisko"
                      ref={nameRef}
                      onChange={changer}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <Form.Control
                        className={styles.formInputs}
                        type="email"
                        placeholder="Adres mailowy"
                        ref={emailRef}
                        onChange={changer}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Control
                        className={styles.formInputs}
                        as='textarea'
                        rows={5}
                        placeholder="Wiadomość"
                        ref={messageRef}
                        onChange={changer}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className={styles.captcha}>
                      <ReCAPTCHA
                        sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`}
                        ref={captchaRef}
                        onChange={() => {
                          validator();
                          changer();
                        }}
                      />
                    </div>
                    {showAlert ? (
                      <Alert
                        variant={
                          alertMessage === "Wiadomość została wysłana"
                            ? "info"
                            : "danger"
                        }
                      >
                        {alertMessage}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={styles.buttons}>
                    <Button className={styles.equalButtons} type="submit" variant="light" disabled={disabled}>
                      Wyślij
                    </Button>
                  </Col>
                </Row>
                </>
              ) : (
                <>{showAlert ? <Alert>{alertMessage}</Alert> : <></>}</>
              )}
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export interface ContactModalProps {}

export default ContactModal;
