import React from "react";
import { ModalContext } from "../../contexts/ModalContext";
import styles from "./OpenModalButton.module.scss";

const OpenModalButton: React.FC<OpenModalButtonProps> = ({
  modalName,
  isHeader,
}) => {
  const { setActiveModal } = React.useContext(ModalContext);

  let style;
  const modal = () => {
    if (modalName === "Start") {
      style = styles.start;
      return "calculator";
    } else if (modalName === "O kalkulatorze") {
      style = styles.about;
      return "about";
    } else if (modalName === "Kontakt") {
      style = styles.contact;
      return "contact";
    }
  };

  const curModal = modal();

  return (
    <button
      className={
        isHeader === true ? styles.homepage : `${styles.menuItem} ${style}`
      }
      onClick={() => setActiveModal(curModal)}
    >
      {modalName}
    </button>
  );
};

export interface OpenModalButtonProps {
  modalName: string;
  isHeader?: boolean;
}

export default OpenModalButton;
