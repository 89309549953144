import React from "react";
import { ModalContext, Modals } from "../../contexts/ModalContext";
import CalculatorModal from "../../modalPages/CalculatorModal";
import ContactModal from "../../modalPages/ContactModal";
import AboutModal from "../../modalPages/AboutModal";
import styles from "./ModalManager.module.scss";

const modalFromName = (name: Modals): React.ReactElement => {
  switch (name) {
    case "calculator":
      return <CalculatorModal />;
    case "about":
      return <AboutModal />;
    case "contact":
      return <ContactModal />;
    default:
      return <></>;
  }
};

const ModalManager: React.FC<ModalManagerProps> = () => {
  const { activeModal } = React.useContext(ModalContext);

  return modalFromName(activeModal);
};

export interface ModalManagerProps {}

export default ModalManager;
