import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ImgLogo from "../../../static/images/logo.png";
import OpenModalButton from "../../../elements/OpenModalButton";
import styles from "./Header.module.scss";

const Header: React.FC<HeaderProps> = () => {
  return (
    <Container fluid className={styles.header}>
      <Row>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 3 }}
          md={{ span: 4 }}
          lg={{ span: 6 }}
          className={"justify-content-start " + styles.menu}
        >
          <header>
            <div className={styles.logo}>
              <img src={ImgLogo} alt="logo" />
            </div>
          </header>
        </Col>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 9 }}
          md={{ span: 8 }}
          lg={{ span: 6 }}
          className={"justify-content-end " + styles.menu}
        >
          <nav>
            <Row>
              <Col>
                <OpenModalButton modalName="Start" />
              </Col>
              <Col>
                <OpenModalButton modalName="O kalkulatorze" />
              </Col>
              <Col>
                <OpenModalButton modalName="Kontakt" />
              </Col>
            </Row>
          </nav>
        </Col>
      </Row>
    </Container>
  );
};

export interface HeaderProps {}

export default Header;
