import React from "react";
import styles from "./Content.module.scss";
import Sketch from "react-p5";
import p5Types from "p5";
import { ModalContext } from "../../../contexts/ModalContext";

interface p5MouseMoved {
  target: Element;
  buttons: number;
  clientX: number;
  clientY: number;
  layerX: number;
  layerY: number;
}

interface p5TouchMoved {
  touches: {
    0: any;
  };
}

let mx: number;
let my: number;
let pmx: number;
let pmy: number;

let penColor: p5Types.Color;
let blueColor: p5Types.Color;
let whiteColor: p5Types.Color;
let greenColor: p5Types.Color;
let redColor: p5Types.Color;

const Content: React.FC<ContentProps> = () => {

  let size = 15;
  let growing = false;

  const setup = (p5: p5Types, canvasParentRef: Element) => {
    p5.frameRate(120);
    let cnv = p5
      .createCanvas(window.screen.width, window.screen.height)
      .parent(canvasParentRef);
    blueColor = p5.color("#60DCFE");
    whiteColor = p5.color("#FFF");
    greenColor = p5.color("#A3EDCA");
    redColor = p5.color("#FF6767")
    penColor = greenColor;


    cnv.touchMoved((ev: p5TouchMoved) => {

      console.log(ev.touches[0]);
      
      mx = ev.touches[0].clientX;
      my = ev.touches[0].clientY;
      if (!pmx) {
        pmx = mx;
        pmy = my;
      }
      
      let spaceBetween = Math.sqrt((mx - pmx) ** 2 + (my - pmy) ** 2);
        p5.fill(penColor);
        p5.noStroke();
        p5.ellipseMode("center");
        let steps = spaceBetween;
        for (let i = 0; i < steps; i++) {
          p5.ellipse(
            mx + (i / steps) * (pmx - mx),
            my + (i / steps) * (pmy - my),
            size
          );
        }
  
        if (size === 60) {
          growing = false
        } else if (size === 5) {
          growing = true
        }
  
        if (growing) {
          size += 1;
        } else {
          size -= 1;
        }

      pmx = mx;
      pmy = my;
    });

    cnv.mouseMoved((ev: p5MouseMoved) => {
      mx = ev.layerX;
      my = ev.layerY;
      if (!pmx) {
        pmx = mx;
        pmy = my;
      }

      let spaceBetween = Math.sqrt((mx - pmx) ** 2 + (my - pmy) ** 2);
        p5.fill(penColor);
        p5.noStroke();
        p5.ellipseMode("center");
        let steps = spaceBetween;
        for (let i = 0; i < steps; i++) {
          p5.ellipse(
            mx + (i / steps) * (pmx - mx),
            my + (i / steps) * (pmy - my),
            size
          );
        }
  
        if (size === 60) {
          growing = false
        } else if (size === 5) {
          growing = true
        }
  
        if (growing) {
          size += 1;
        } else {
          size -= 1;
        }

      pmx = mx;
      pmy = my;
    });

    cnv.mouseClicked((ev: Event) => {
      if (penColor === blueColor) {
        penColor = whiteColor;
      } else if (penColor === whiteColor) {
        penColor = greenColor;
      } else if (penColor === greenColor) {
        penColor = redColor
      } else {
        penColor = blueColor;
      }
    });

    cnv.mouseOut(() => {
      pmx = 0;
      pmy = 0;
    })

    cnv.touchEnded((ev: Event) => {
      if (penColor === blueColor) {
        penColor = whiteColor;
      } else if (penColor === whiteColor) {
        penColor = greenColor;
      } else if (penColor === greenColor) {
        penColor = redColor
      } else {
        penColor = blueColor;
      }

      pmx = 0;
      pmy = 0;
    });

    cnv.touchStarted((ev: Event) => {
      pmx = 0;
      pmy = 0;
    });
  };

  const draw = (p5: p5Types) => {};

  const { setActiveModal } = React.useContext(ModalContext);

  return (
    <main className={styles.content}>
      <Sketch className={styles.sketch} setup={setup} draw={draw} />
      <div className={styles.centerDiv}>
        <h1 className={styles.title}>Kalkulator Innowacji Cyfrowych</h1>
        <p className={styles.description}>
          Skorzystaj z kalkulatora, żeby poznać strategię transformacji cyfrowej dostosowaną do Twojej firmy
        </p>
        <p className={styles.description}>
          Odpowiedz na kilka pytań i pobierz raport
        </p>
        <div>
        <button onClick={() => setActiveModal('calculator')} className={`${styles.arrow} ${styles.btnWhite}`}>Start</button>
        </div>
        <p className={styles.learnMore}>
          Dowiedz się więcej o tym jak działa kalkulator <button onClick={() => setActiveModal('about')} className={styles.tutaj}>tutaj</button>
        </p>
      </div>
    </main>
  );
};

export interface ContentProps {}

export default Content;
