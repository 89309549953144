import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ImgFunduszeEuropejskie from "../../../static/images/fundusze-europejskie.jpg";
import ImgRzeczpospolitaPolska from "../../../static/images/rzeczpospolita-polska.jpg";
import ImgSiecOtwartychInnowacji from "../../../static/images/siec-otwartych-innowacji.jpg";
import ImgUniaEuropejska from "../../../static/images/unia-europejska.jpg";
import styles from "./Footer.module.scss";

const Footer: React.FC<FooterProps> = () => {
  return (
    <Container fluid className={styles.footer}>
      <Row>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 2, offset: 2, order: 1 }}
          className={"my-1"}
        >
          <div className={styles.imgContainer}>
            <div
              className={styles.imgItem}
              style={{ backgroundImage: `url(${ImgFunduszeEuropejskie})` }}
            ></div>
          </div>
        </Col>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 2, order: 2 }}
          className={"my-1"}
        >
          <div className={styles.imgContainer}>
            <div
              className={styles.imgItem}
              style={{ backgroundImage: `url(${ImgRzeczpospolitaPolska})` }}
            ></div>
          </div>
        </Col>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 2, order: 3 }}
          className={"my-1"}
        >
          <div className={styles.imgContainer}>
            <div
              className={styles.imgItem}
              style={{ backgroundImage: `url(${ImgSiecOtwartychInnowacji})` }}
            ></div>
          </div>
        </Col>
        <Col
          xs={{ span: 6 }}
          sm={{ span: 2, offset: 0, order: 4 }}
          className={"my-1"}
        >
          <div className={styles.imgContainer}>
            <div
              className={styles.imgItem}
              style={{ backgroundImage: `url(${ImgUniaEuropejska})` }}
            ></div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export interface FooterProps {}

export default Footer;
