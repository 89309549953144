import { useContext } from "react";
import {
  CalculatorContextReducer,
  CalculatorInterface,
} from "../contexts/CalculatorContextReducer";

type UseCalculatorFn = () => { calculator: CalculatorInterface };

const useCalculator: UseCalculatorFn = () => {
  const contextState = useContext(CalculatorContextReducer);

  if (contextState === null) {
    throw new Error(
      "useCalculator must be used within a CalculatorContextReducer tag"
    );
  }

  return contextState;
};

export default useCalculator;
