import CalculatorStageManager from "core/CalculatorStageManager";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { ModalContext } from "../../contexts/ModalContext";
import useCalculator from "../../hooks/useCalculator";
import styles from "./CalculatorModal.module.scss";
import { Alert, CloseButton } from "react-bootstrap";

const CalculatorModal: React.FC<CalculatorModalProps> = () => {
  const { setActiveModal } = React.useContext(ModalContext);

  const { calculator } = useCalculator();

  const handleHide = () => {
    setActiveModal(undefined);
  };

  return (
    <Modal
      dialogClassName={styles.modalDialog}
      contentClassName={styles.modalContent}
      show={true}
      size={"xl"}
      keyboard={false}
      onHide={handleHide}
      centered
    >
      <Modal.Header className={styles.header}>
        <Modal.Title><strong>Start</strong></Modal.Title>
        <CloseButton className={styles.clsBtn} onClick={handleHide}/>
      </Modal.Header>
      {!!calculator.question && <CalculatorStageManager />}
      {!!calculator.error && (
        <Alert variant="danger" className="mx-3">
          {calculator.error}
        </Alert>
      )}
    </Modal>
  );
};

export interface CalculatorModalProps {}

export default CalculatorModal;
